import React, {FunctionComponent} from 'react';
import Sidebar from '../components/Sidebar';
import {graphql, PageProps} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import LoginWrapper from '../components/LoginWrapper';
import HeroImage from '../components/HeroImage';
import HtmlHead from '../components/layout/HtmlHead';
import Images from '../types/Images';
import Files from '../types/Files';
import MDX from '../types/MDX';

interface News {
    title: string;
    category: string;
    url: string;
    id: string;
    to: string;
    img:string;
    external?: boolean;
}

type MarkdownPageProps = PageProps<{
    mdx: MDX;
    images: Images;
    files: Files;
}>;

const News: FunctionComponent<MarkdownPageProps> = (props) => {

    require('../styles/global.scss');
    require(`../styles/${process.env.THEME}/global.scss`);

    const newsImages = props.data.images;
    const {mdx} = props.data;
    const {body, frontmatter} = mdx;
    const {title, euLogin, bigLogin, category, loginButtonText, slug} = frontmatter;
    const news: Array<News> = require(`../content/${process.env.THEME}/${(slug === 'nieuws-voor-patient') ? 'news-patient' : 'news'}.json`).news;

    const mappedNews = news.map((news) => {
        const image = newsImages.edges.find((edge) => edge.node.name === news.img);

        return {
            ...news,
            image: image?.node.childImageSharp.gatsbyImageData,
            to: news.to,
            external: news.to && !news.to.startsWith('/')
        };
    });

    return (
        <>
            <HtmlHead pageTitle={title}/>
            <LoginWrapper
                title={title}
                euLogin={euLogin}
                bigLogin={bigLogin}
                buttonText={loginButtonText}
                category={category}
                heroImage={true}
                markdown={body}
            >
                <HeroImage/>
                <div className='px-4 lg:flex lg:stackx-8 lg:mt-12 lg:px-20 max-w-screen-2xl mx-auto news page'>
                    <div className='w-1/4'>
                        <Sidebar category={category}/>
                    </div>
                    <main className='my-6 w-full lg:w-3/4'>
                        <h1 className={'text-4xl font-bold my-6'}>{frontmatter.title}</h1>
                        <MDXRenderer>{body}</MDXRenderer>
                        <div className={'max-w-screen-2xl mx-auto my-2 lg:my-8 stacky-2 lg:flex lg:flex-row lg:stacky-0 lg:stackx-2'}>
                            {
                                mappedNews.filter((item) => item.category === 'nieuws').map((news, index) => {
                                    return (
                                        <div key={`news-${index}`} className="news-block flex flex-col p-6 rounded-md stacky-4 lg:w-1/3">
                                            {
                                                news.image &&
                                                <div className='max-w-md'>
                                                    <GatsbyImage
                                                        image={news.image}
                                                        alt={news.title}
                                                    />
                                                </div>
                                            }
                                            <div className={'news-block-inner'}>
                                                <h3>{news.title}</h3>
                                            </div>
                                            <a href={'#'} onClick={()=> { window.location.href = news.to; return false; }}
                                                className="button text-center px-6 py-2 border rounded-md font-bold uppercase self-end button mt-4 w-full"
                                                rel="noopener noreferrer">{(news.buttonText) ? news.buttonText : 'Bekijk website'}</a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {/*<h2 className={'text-2xl font-bold'}>Podcasts</h2>*/}
                        <div className={'max-w-screen-2xl mx-auto my-2 lg:my-8 stacky-2 lg:flex lg:flex-row lg:stacky-0 lg:stackx-2'}>
                            {
                                mappedNews.filter((item) => item.category === 'podcasts').map((news, index) => {
                                    return (
                                        <div key={`podcast-${index}`} className="news-block flex flex-col p-6 rounded-md stacky-4 lg:w-1/3">
                                            {
                                                news.image &&
                                                <div className='max-w-md'>
                                                    <GatsbyImage
                                                        image={news.image}
                                                        alt={news.title}
                                                    />
                                                </div>
                                            }
                                            <div className={'news-block-inner'}>
                                                <h3>{news.title}</h3>
                                            </div>
                                            <a href={news.to}
                                                className="button text-center px-6 py-2 border rounded-md font-bold uppercase self-end button mt-4 w-full"
                                                rel="noopener noreferrer">Naar podcast</a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <h2 className={'text-2xl font-bold'}>Videos en Animaties</h2>
                        <div className={'max-w-screen-2xl mx-auto my-2 lg:my-8 stacky-2 lg:flex lg:flex-row lg:stacky-0 lg:stackx-2'}>
                            {
                                mappedNews.filter((item) => item.category === 'animaties').map((news, index) => {
                                    return (
                                        <div key={`video-animation-${index}`} className="news-block flex flex-col p-6 rounded-md stacky-4 lg:w-1/3">
                                            {
                                                news.image &&
                                                <div className='max-w-md'>
                                                    <GatsbyImage
                                                        image={news.image}
                                                        alt={news.title}
                                                    />
                                                </div>
                                            }
                                            <div className={'news-block-inner'}>
                                                <h3>{news.title}</h3>
                                            </div>
                                            <a href={news.to}
                                                className="button text-center px-6 py-2 border rounded-md font-bold uppercase self-end button mt-4 w-full"
                                                rel="noopener noreferrer">Bekijken</a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </main>
                </div>
            </LoginWrapper>
        </>
    );
};

export default News;

export const pageQuery = graphql`
    query($slug: String!) {
        mdx(frontmatter: { slug: { eq: $slug } }) {
            body
            frontmatter {
                slug
                title
                category
                euLogin
                bigLogin
                loginButtonText
            }
        }
        files: allFile(filter: {
                    extension: {eq: "pdf"},
            }) {
                edges {
                    node {
                        name
                        publicURL
                    }
                }
            }
  images: allFile(filter: {
            relativeDirectory: { regex: "/(news)/"}
        }) {
            edges {
                node {
                    name
                    childImageSharp {
                        gatsbyImageData(quality: 90)
                    }
                }
            }
        }
    }
`;
